
// Libraries
import * as React from 'react'
import {graphql} from 'gatsby'

// Services
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import EventList from "../components/eventList"
import Button from "../components/button"
import { GatsbyImage } from 'gatsby-plugin-image'

const ArtistTemplate = ({ data }) => {
	const events = data.artistEvents.nodes

	return (
		<Layout className="artist-page">
			<Seo
				title={ data.artist.name }
				description={ data.artist.description }
				image={ data.artist.image }
				custom={ true }
			/>

			<section itemScope>
				<Hero contentFullWidth={ true } bg="artist">
					<button className="artist-scroll-button" onClick={ scrollToEvents }>
						<span>Upcoming Events</span>
						<FontAwesomeIcon icon={ faArrowDown } />
					</button>

					<div className="artist-hero">
						<h1 className="artist-hero__main-heading" itemProp="name">{ data.artist.name }</h1>

						{ data?.artist?.image_gatsby &&
							<div className="artist-hero__image">
								<div className="artist-hero__image__container">
									<GatsbyImage image={ data.artist.image_gatsby.childImageSharp.gatsbyImageData } alt={ data.artist.name } />
								</div>
							</div>
						}

						<div dangerouslySetInnerHTML={ { __html: data.artist.description } } />
					</div>
				</Hero>
			</section>

			<section className="event-related">
				{events.length
					? <EventList events={ events } noFilters />
					: <p className="no-events">Sorry, we have no current events available for this artist.<br /><br />
						<Button to="/events" colorEndeavour>Browse Other Events</Button></p>
				}
			</section>
		</Layout>
	)

	function scrollToEvents() {
		const eventRelated = document.querySelector('.event-related')
		eventRelated.scrollIntoView({ behavior: 'smooth' })
	}
}

// Query GraphQL for an event with matching apiId to pageContext
export const query = graphql`
query ($apiId: Int) {
	artist(apiId: {eq: $apiId}) {
		id
		name
		description
		image
		image_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 640,
					aspectRatio: 0.825,
					layout: CONSTRAINED,
				)
			}
		}
	}
	artistEvents: allEvent(filter: {
		artist_id: {eq: $apiId}, 
		status_id: {eq: 1}
	}) {
		nodes {
			id
			name
			starts
			slug
			ends
			synopsis
			logo_gatsby {
				childImageSharp {
					gatsbyImageData(
						width: 400,
						aspectRatio: 1,
						transformOptions: {
							fit: COVER
						}
					)
				}
			}
			artist {
				name
			}
			venue {
				name
				website_url
			}
		}
	}
}
`

export default ArtistTemplate
